import React from 'react';
import { useIntersection } from 'react-use';
import { NavDirections } from './types';

const THRESHOLD = [0.01, 0.8];

interface IsInViewPort {
    hasEnteredViewport: boolean;
    isVisible: boolean;
}

export const useIsInViewPort = ({
    ref,
    trackVisibility,
}: {
    ref: React.RefObject<HTMLUnknownElement>;
    trackVisibility?: boolean;
}): IsInViewPort => {
    const [hasEnteredViewport, setHasEnteredViewport] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);

    const intersection = useIntersection(ref, {
        root: null,
        rootMargin: '100% 0px 100% 50px',
        threshold: THRESHOLD,
    });

    if (intersection?.isIntersecting && !hasEnteredViewport) {
        setHasEnteredViewport(true);
    }

    if (trackVisibility) {
        if (intersection?.intersectionRatio >= 0.8 && !isVisible) {
            setIsVisible(true);
        } else if (intersection?.intersectionRatio < 0.8 && isVisible) {
            setIsVisible(false);
        }
    }

    return { hasEnteredViewport, isVisible };
};

export const useSetDisabledNav = ({
    isLastSlide,
    isFirstSlide,
    isVisible,
    setDisabledNav,
    fullyEnableNavigation = false,
    isFirstPage = false,
    isLastPage = false,
}: {
    isLastSlide: boolean;
    isFirstSlide: boolean;
    isVisible: boolean;
    setDisabledNav: (direction?: NavDirections) => void;
    fullyEnableNavigation?: boolean;
    isFirstPage?: boolean;
    isLastPage?: boolean;
}) => {
    const enabled = isLastSlide || isFirstSlide;
    React.useEffect(() => {
        if (enabled && !fullyEnableNavigation) {
            if (isVisible) {
                setDisabledNav(
                    isLastSlide ? NavDirections.Right : NavDirections.Left,
                );
            } else {
                setDisabledNav(undefined);
            }
        } else if (fullyEnableNavigation) {
            if (isFirstPage && isVisible && isFirstSlide) {
                setDisabledNav(NavDirections.Left);
            } else if (isLastPage && isVisible && isLastSlide) {
                setDisabledNav(NavDirections.Right);
            } else {
                setDisabledNav(undefined);
            }
        }
    }, [
        isLastSlide,
        isVisible,
        enabled,
        fullyEnableNavigation,
        setDisabledNav,
        isFirstPage,
        isLastPage,
        isFirstSlide,
    ]);
};
