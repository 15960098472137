import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { LoadingCards } from '@eventbrite/eds-event-card';
import React from 'react';
import { CategoryBrowseBucket } from '../../components/CategoryBrowseBucket';

interface EventLoadingStateProps {
    numberOfEvents?: number;
}

export const EventLoadingState: React.FunctionComponent<
    EventLoadingStateProps
> = (props) => {
    const numberOfEvents =
        typeof props.numberOfEvents === 'number' ? props.numberOfEvents : 4;
    return (
        <CategoryBrowseBucket>
            <SimpleCarousel
                slidesVisible={{ desktop: 4, tablet: 2 }}
                lazilyRenderOffScreenElements={true}
            >
                {[...Array(numberOfEvents).keys()].map((item: number) => (
                    <div
                        key={`loading_card_${item}`}
                        style={{
                            width: '97%',
                            height: '400px',
                            marginTop: '8px',
                            marginBottom: '8px',
                        }}
                    >
                        <LoadingCards
                            style="grid"
                            dynamicWidth={true}
                            cardCount={1}
                            dynamicHeight={true}
                        />
                    </div>
                ))}
            </SimpleCarousel>
        </CategoryBrowseBucket>
    );
};
