import { OrganicEventSearchFiltersApiPayload } from '../../../../api/searchOrganicEvents';
import { INITIAL_QUERY } from '../../../../context/search';

function getTagWithCategoryPreference(
    tags: OrganicEventSearchFiltersApiPayload['tags'] = [''],
) {
    const subcategoryTag = tags.find((tag: string) =>
        tag.includes('EventbriteSubCategory'),
    );

    if (subcategoryTag) return subcategoryTag;

    return (
        tags.find((tag: string) => tag.includes('EventbriteCategory')) || 'tag'
    );
}

type ActiveTag = string | 'tag';
type LocationTag = string | 'online' | 'location';

export type HomePageEventsQueryKey = [
    'homepage',
    'events',
    ActiveTag,
    LocationTag,
    OrganicEventSearchFiltersApiPayload,
];

export function getHomePageEventsQueryKey(
    eventSearch: OrganicEventSearchFiltersApiPayload,
): HomePageEventsQueryKey {
    const normalizedEventSearch: OrganicEventSearchFiltersApiPayload = {
        ...INITIAL_QUERY,
        ...eventSearch,
        dates:
            typeof eventSearch.dates === 'string'
                ? [eventSearch.dates]
                : eventSearch.dates,
    };

    const locationTag = normalizedEventSearch.online_events_only
        ? 'online'
        : eventSearch?.places?.[0] || 'location';
    const activeTag = getTagWithCategoryPreference(normalizedEventSearch?.tags);

    return [
        'homepage',
        'events',
        activeTag,
        locationTag,
        normalizedEventSearch,
    ];
}
