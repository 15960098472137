import { GenericLazyString } from '@eventbrite/i18n';
import React, { ReactElement } from 'react';

export const CarouselTitle: React.FunctionComponent<CarouselTitleProps> = (
    props,
) => {
    if (!props.children) {
        return null;
    }

    return (
        <div className="simple-carousel__title_bucket">
            <div className="simple-carousel__title eds-text-hs">
                {props.children}
            </div>
            {props.subtitle ? (
                <div className="simple-carousel__subtitle eds-text-bm eds-text-color--ui-500">
                    {props.subtitle}
                </div>
            ) : null}
        </div>
    );
};

export const CarouselHeader: React.FunctionComponent = (props) => {
    return <div className="simple-carousel__header">{props.children}</div>;
};

interface CarouselTitleProps {
    subtitle?: string | GenericLazyString | ReactElement;
}
