import { gettext } from '@eventbrite/i18n';
export type { DateRange, DateType } from '@eventbrite/search-utils';
export const DATE_FILTER_VALUES = {
    CUSTOM_DATE: 'pick-a-date',
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    THIS_WEEKEND: 'this_weekend',
};

export const DATE_URL_KEYS = {
    DATE: 'date',
    START_DATE: 'start_date',
    END_DATE: 'end_date',
};

export const DATE_FILTER_DISPLAY_VALUES = {
    [DATE_FILTER_VALUES.CUSTOM_DATE]: gettext('Calendar'),
    [DATE_FILTER_VALUES.TODAY]: gettext('Today'),
    [DATE_FILTER_VALUES.TOMORROW]: gettext('Tomorrow'),
    [DATE_FILTER_VALUES.THIS_WEEKEND]: gettext('This weekend'),
};

export const DATE_FILTER_OPTIONS = [
    {
        content: DATE_FILTER_DISPLAY_VALUES[DATE_FILTER_VALUES.CUSTOM_DATE],
        value: DATE_FILTER_VALUES.CUSTOM_DATE,
    },
    {
        content: DATE_FILTER_DISPLAY_VALUES[DATE_FILTER_VALUES.TODAY],
        value: DATE_FILTER_VALUES.TODAY,
    },
    {
        content: DATE_FILTER_DISPLAY_VALUES[DATE_FILTER_VALUES.TOMORROW],
        value: DATE_FILTER_VALUES.TOMORROW,
    },
    {
        content: DATE_FILTER_DISPLAY_VALUES[DATE_FILTER_VALUES.THIS_WEEKEND],
        value: DATE_FILTER_VALUES.THIS_WEEKEND,
    },
];

export interface DateFilter {
    resetDateParams?: Function;
    handleCustomDateSelect?: Function;
    handleTextItemSelect?: Function;
    date?: string;
    startDate?: string;
    endDate?: string;
    locale?: string;
}
