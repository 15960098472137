import { IconButton } from '@eventbrite/eds-icon-button';
import {
    ArrowLeftChunky,
    ArrowRightChunky,
    ChevronLeftChunky,
    ChevronRightChunky,
} from '@eventbrite/eds-iconography';
import { GenericLazyString, gettext } from '@eventbrite/i18n';
import React, { ReactElement, useEffect } from 'react';
import { CarouselHeader, CarouselTitle } from './components/CarouselHeader';
import { CarouselNavigation } from './components/CarouselNavigation';
import { Slide } from './components/CarouselSlide';
import './SimpleCarousel.scss';
import { NavDirections, SlideConfig } from './types';
/**
 * A simple carousel that leverages scroll snap
 * to provide a "native" feeling carousel experience.
 *
 * It accepts an array of any kind of children
 * and can render handle dynamic scrolling of
 * pages.
 *
 * To provide padding or width to space out components
 * within the carousel, it is recommended to not use
 * padding or margin as this can cause scroll "bleed".
 * Instead wrap your content with a `width: X%` inline
 * style. This will ensure it fills up that amount of the
 * given slide and still appear dynamic.
 *
 * @param props
 * @returns
 */
export const SimpleCarousel: React.FunctionComponent<SimpleCarouselProps> = (
    props,
) => {
    const {
        onNavigationClick,
        lazilyRenderOffScreenElements = false,
        slidesVisible = { desktop: 1, tablet: 1 },
        onCarouselScrollEnd,
        enableSecondarySideNavIcons,
        fullyEnableNavigation = false,
        isFirstPage = false,
        isLastPage = false,
    } = props;

    const [disabledNav, setDisabledNav] = React.useState<
        NavDirections | undefined
    >(NavDirections?.Left);
    const [isScrollEndDetected, setIsScrollEndDetected] = React.useState(false);
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const childArray = React.Children.toArray(props.children);
    const filteredChildren = childArray.filter((child) =>
        React.isValidElement(child),
    );
    const childrenLength = filteredChildren.length;

    const handleScroll = (direction: NavDirections) => {
        const containerWidth = scrollContainerRef.current?.clientWidth || 0;
        const normalizedContainerWidth = containerWidth * 0.9 || 200;
        const scrollAmount =
            direction === NavDirections.Right
                ? normalizedContainerWidth
                : normalizedContainerWidth * -1;
        scrollContainerRef?.current?.scrollBy({
            left: scrollAmount,
            behavior: 'smooth',
        });
        onNavigationClick?.(direction);
    };

    useEffect(() => {
        if (disabledNav === NavDirections.Right && !isScrollEndDetected) {
            onCarouselScrollEnd?.();
            setIsScrollEndDetected(true);
        }
    }, [onCarouselScrollEnd, disabledNav, isScrollEndDetected]);

    return (
        <div className="simple-carousel-wrapper">
            <CarouselHeader>
                <CarouselTitle subtitle={props.subtitle}>
                    {props.headerTitle}
                </CarouselTitle>
                {!props.useSideNavigation ? (
                    <CarouselNavigation
                        childrenLength={childrenLength}
                        slidesVisible={slidesVisible}
                        handleClick={handleScroll}
                        leftDisabled={disabledNav === NavDirections.Left}
                        rightDisabled={disabledNav === NavDirections.Right}
                        fullyEnableNavigation={fullyEnableNavigation}
                    />
                ) : null}
            </CarouselHeader>
            <div className="simple-carousel__container">
                {props.useSideNavigation ? (
                    <IconButton
                        iconType={
                            enableSecondarySideNavIcons ? (
                                <ChevronLeftChunky />
                            ) : (
                                <ArrowLeftChunky />
                            )
                        }
                        onClick={() => handleScroll(NavDirections.Left)}
                        title={gettext('Scroll carousel left')}
                        isDisabled={disabledNav === NavDirections.Left}
                        style="outline"
                    />
                ) : null}
                <div className="simple-carousel" ref={scrollContainerRef}>
                    {React.Children.map(filteredChildren, (child, index) => {
                        return (
                            <Slide
                                childrenLength={childrenLength}
                                slideNumber={index}
                                slidesVisible={slidesVisible}
                                lazilyRenderOffScreenElements={
                                    lazilyRenderOffScreenElements
                                }
                                setDisabledNav={setDisabledNav}
                                fullyEnableNavigation={fullyEnableNavigation}
                                isFirstPage={isFirstPage}
                                isLastPage={isLastPage}
                            >
                                {child}
                            </Slide>
                        );
                    })}
                </div>
                {props.useSideNavigation ? (
                    <IconButton
                        iconType={
                            enableSecondarySideNavIcons ? (
                                <ChevronRightChunky />
                            ) : (
                                <ArrowRightChunky />
                            )
                        }
                        onClick={() => handleScroll(NavDirections.Right)}
                        title={gettext('Scroll carousel right')}
                        isDisabled={disabledNav === NavDirections.Right}
                        style="outline"
                    />
                ) : null}
            </div>
        </div>
    );
};
interface SimpleCarouselProps {
    /**
     * Header for the carousel. If not
     * defined no header will be rendered
     */
    headerTitle?: string | GenericLazyString | ReactElement;
    /**
     *
     */
    subtitle?: string | GenericLazyString | ReactElement;
    /**
     * When a number is passed in determines the total number of slides
     * visible on non mobile breakpoints.
     *
     * If an object is passed in it takes a shape of SlideConfig
     * that allows for specifying tablet vs desktop slides
     *
     * Default 1
     */
    slidesVisible?: SlideConfig;
    /**
     * When set to true, will
     * lazily render off screen
     * elements until they are about
     * to be in the viewport.
     *
     * Useful if your carousel has
     * many items in it.
     *
     * Default: False
     */
    lazilyRenderOffScreenElements?: boolean;
    /**
     * Callback for when the user interacts with
     * the navigation controls passing back
     * the direction that it was clicked.
     */
    onNavigationClick?: (direction: NavDirections) => void;
    /**
     * When set to true, will show the side arrow navigation for the carousel
     */
    useSideNavigation?: boolean;
    /**
     * Callback when carousel is scrolled to end
     */
    onCarouselScrollEnd?: Function;
    /***
     * Side navigation secondary icons
     */
    enableSecondarySideNavIcons?: boolean;
    /***
     * Enable both  side navigation icons
     */
    fullyEnableNavigation?: boolean;
    /***
     * Enable both  right side  navigation icons
     */
    isFirstPage?: boolean;
    /***
     * Enable both  left side  navigation icons
     */
    isLastPage?: boolean;
}
