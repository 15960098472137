import * as React from 'react';

const ArrowLeftChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="arrow-left-chunky_svg__eds-icon--arrow-left-chunky_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="arrow-left-chunky_svg__eds-icon--arrow-left-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 12l8 8 1.5-1.5L8 13h12v-2H8l5.5-5.5L12 4z"
        />
    </svg>
);

ArrowLeftChunkySvg.displayName = 'ArrowLeftChunkySvg';
export default ArrowLeftChunkySvg;
