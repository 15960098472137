/**
 * Interleaves elements from two arrays based on specified positions.
 *
 * @example
 * const arrayA = [1, 2, 3];
 * const arrayB = ['a', 'b'];
 * const positions = [2, 4];
 * const result = interleaveTwoArrays(arrayA, arrayB, positions);
 * // result: [1, 'a', 2, 3, 'b']
 */
export function interleaveTwoArrays<T>(
    arrayA: T[],
    arrayB: T[],
    positions: number[],
) {
    let interleavedArray = [...arrayA];

    arrayB.forEach((item, index) => {
        // If no position has been provided, just put the element in the end
        const newPosition = (positions[index] || interleavedArray.length) - 1;

        interleavedArray = interleaveIntoArray(
            interleavedArray,
            item,
            newPosition,
        );
    });

    return interleavedArray;
}

/**
 * Inserts an element into an array at a specified position.
 *
 * @example
 * const array = [1, 2, 3];
 * const element = 'a';
 * const position = 2;
 * const result = interleaveIntoArray(array, element, position);
 * // result: [1, 'a', 2, 3]
 */
function interleaveIntoArray<T>(array: T[], element: T, position: number): T[] {
    const result = [...array];

    // If the desired position of A is greater than the current length of the array,
    // insert A at the end of the array.
    if (position > result.length) {
        position = result.length;
    }

    result.splice(position, 0, element);

    return result;
}
