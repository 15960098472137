import { bboxShape, Location } from '@eventbrite/discover-utils';
import isEmpty from 'lodash/isEmpty';
export const buildBBox = ({
    latitude,
    longitude,
}: {
    latitude: number;
    longitude: number;
}) => ({
    sw: { lng: longitude - 0.4, lat: latitude - 0.3 },
    ne: { lng: longitude + 0.4, lat: latitude + 0.3 },
});

export const bboxToUrlParam = (bbox: bboxShape) =>
    `${bbox.sw?.lng},${bbox.sw?.lat},${bbox.ne?.lng},${bbox.ne?.lat}`;

export const buildLocationData = ({
    locationData,
    location,
}: {
    locationData: Location;
    location: Location;
}) => {
    // locationData is empty when is called from tabs
    const _locationData = isEmpty(locationData) ? location : locationData;
    const { placeId, bbox, longitude, latitude } = _locationData;

    if (isEmpty(bbox) && longitude && latitude) {
        _locationData.bbox = buildBBox({ latitude, longitude });
    }

    /**
     * In some weird cases the placeId looked like
     * `recent-latitude-longitude`
     * Causing the calls to city browse to be weird.
     */
    if (placeId && placeId.match(/[recent]/g)) {
        _locationData.placeId = null;
    }

    return _locationData;
};

export const isLanguageSubfolderUsedForLocale = (
    locale_Code: string,
): boolean => {
    const language_Subfoler_used_locales = [
        'fr_CA',
        'es_US',
        'fr_BE',
        'fr_CH',
    ] as string[];

    return language_Subfoler_used_locales.indexOf(locale_Code) > -1;
};
