import * as React from 'react';

const ArrowRightChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="arrow-right-chunky_svg__eds-icon--arrow-right-chunky_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="arrow-right-chunky_svg__eds-icon--arrow-right-chunky_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 5.5L16 11H4v2h12l-5.5 5.5L12 20l8-8-8-8z"
        />
    </svg>
);

ArrowRightChunkySvg.displayName = 'ArrowRightChunkySvg';
export default ArrowRightChunkySvg;
