import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { gettext } from '@eventbrite/i18n';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    IconButton,
} from '@eventbrite/marmalade';

import React from 'react';
import { NavDirections, SlideConfig } from '../types';

export const CarouselNavigation: React.FunctionComponent<
    CarouselNavigationProps
> = (props) => {
    if (props.childrenLength === 1 && !props.fullyEnableNavigation) {
        return null;
    }

    const hideNavigation = () => {
        if (!HAS_WINDOW) {
            return;
        }

        if (window.innerWidth > 1080) {
            //For desktop view
            return props.childrenLength <= props.slidesVisible.desktop;
        }
        if (window.innerWidth > 660) {
            //For tablet view
            return props.childrenLength <= props.slidesVisible.tablet;
        }
        return false;
    };

    const classes =
        hideNavigation() && !props.fullyEnableNavigation
            ? 'simple-carousel__navigation simple-carousel__navigation--desktop-hide'
            : 'simple-carousel__navigation';

    return (
        <div className={classes}>
            <IconButton
                icon={<ChevronLeftIcon size="large" />}
                onClick={() => props.handleClick(NavDirections.Left)}
                aria-label={gettext('Scroll carousel left')}
                disabled={props.leftDisabled}
                outline
            />
            <span style={{ paddingLeft: '8px' }} />
            <IconButton
                icon={<ChevronRightIcon size="large" />}
                onClick={() => props.handleClick(NavDirections.Right)}
                aria-label={gettext('Scroll carousel right')}
                disabled={props.rightDisabled}
                outline
            />
        </div>
    );
};

interface CarouselNavigationProps {
    handleClick: (direction: NavDirections) => void;
    childrenLength: number;
    slidesVisible: SlideConfig;
    leftDisabled: boolean;
    rightDisabled: boolean;
    fullyEnableNavigation?: boolean;
}
