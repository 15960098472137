import { Location } from '@eventbrite/discover-utils';
import {
    FormattedEvent,
    transformDestinationEvent,
} from '@eventbrite/event-renderer';
import {
    useIsFetching,
    useQuery,
    UseQueryOptions,
} from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import React from 'react';
import {
    OrganicEventSearchApiResponse,
    OrganicEventSearchFiltersApiPayload,
} from '../../../../api/searchOrganicEvents';
import { useSearchContext } from '../../../../context';
import { bboxToUrlParam } from '../../../../utils/location';
import { search } from '../../api';
import { buildBBox } from '../../utils';
import { getHomePageEventsQueryKey, HomePageEventsQueryKey } from './utils';

const transformResponse = (
    data: OrganicEventSearchApiResponse,
): SearchResponseData => {
    return {
        events: [
            ...data.events.promoted_results.map(transformDestinationEvent),
            ...data.events.results.map(transformDestinationEvent),
        ],
        eventSearch: data.event_search as any,
        pagination: data.events.pagination,
    };
};

function getSearchLocation(location: Location = {}) {
    const { placeId, bbox, isOnline, longitude, latitude } = location;
    if (isEmpty(bbox) && longitude && latitude) {
        location.bbox = buildBBox({ latitude, longitude });
    }

    const searchOptions = {
        places: placeId ? [placeId] : undefined,
        online_events_only: isOnline ? isOnline : undefined,
        bbox: location?.bbox ? bboxToUrlParam(location?.bbox) : undefined,
    };

    return searchOptions;
}

export function useBaseSearchQuery(
    queryOverload: OrganicEventSearchFiltersApiPayload = {},
    location: Location = {},
): OrganicEventSearchFiltersApiPayload {
    const { query } = useSearchContext();
    const searchOptions = getSearchLocation(location);
    return {
        ...query,
        ...searchOptions,
        ...queryOverload,
    };
}

interface PaginationResponse {
    object_count?: number;
    page_count?: number;
    page_number?: number;
    page_size?: number;
    continuation?: string;
}

interface SearchResponseData {
    events: FormattedEvent[];
    eventSearch: OrganicEventSearchFiltersApiPayload;
    pagination?: PaginationResponse;
}

/**
 * The base query for interacting with the search API.
 *
 * It is recommended to create new functions that extend
 * the functionality of this hook to generate additional
 * useful calls.
 *
 * @param searchQuery
 * @param selector
 * @returns
 */
export function useBaseEventsQuery<ReturnType>(
    selector: (data: OrganicEventSearchApiResponse) => ReturnType,
    queryOverload: OrganicEventSearchFiltersApiPayload = {},
    queryOptions: BaseEventsQueryOptions = {
        location: {},
    },
) {
    const overloadedQuery = useBaseSearchQuery(
        queryOverload,
        queryOptions?.location,
    );
    const queryKey = getHomePageEventsQueryKey(overloadedQuery);
    //If an events query is already fetching, hold off
    //on fetching until response is returned. This
    //allows subsequent calls to ensure no repeat events
    const totalEventQueriesFetching = useIsFetching(queryKey.slice(0, 3));

    const shouldEnable = React.useRef(false);
    if (!shouldEnable.current) {
        shouldEnable.current = totalEventQueriesFetching === 0;
    } else {
        shouldEnable.current = true;
    }

    const enabled =
        !!queryOptions?.location?.currentPlace &&
        shouldEnable.current &&
        (queryOptions.enabled === undefined ? true : !!queryOptions.enabled);

    const response = useQuery<
        OrganicEventSearchApiResponse,
        Error,
        ReturnType,
        HomePageEventsQueryKey
    >(queryKey, async () => await search(overloadedQuery), {
        //React query is complaining that the selector
        //is the provided util or undefined as opposed to
        //a function definition of `unknown`. Ignoring for now
        //eslint-disable-next-line
        //@ts-ignore
        select: selector,
        ...queryOptions,
        enabled,
    });
    return response;
}

interface BaseEventsQueryOptions extends UseQueryOptions {
    queryOverload?: OrganicEventSearchFiltersApiPayload;
    location: Location;
}

export const useCategoryEventsQuery = (options?: BaseEventsQueryOptions) =>
    useBaseEventsQuery<SearchResponseData>(
        transformResponse,
        options?.queryOverload,
        options,
    );
