import * as React from 'react';

const TicketsSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.146 4.146A.5.5 0 0115.5 4h12a.5.5 0 01.5.5v4a.5.5 0 01-.5.5.5.5 0 000 1 .5.5 0 01.5.5v10.978a.564.564 0 010 .045V24.5a.5.5 0 01-.5.5H20v.5a.5.5 0 01-.414.493l-11.5 2a.5.5 0 01-.58-.412l-2.195-13.5a.5.5 0 01.393-.57.5.5 0 00.388-.59v-.001a.5.5 0 00-.59-.389.5.5 0 01-.59-.392L4.01 7.598a.5.5 0 01.4-.59L15 5.083V4.5a.5.5 0 01.146-.354zM27 21h-6a.5.5 0 000 1h6v2H16v-.837V22h2a.5.5 0 000-1h-2V10.915a1.5 1.5 0 000-2.83V5.51a.57.57 0 000-.018V5h11v3.086a1.5 1.5 0 000 2.829V21zm-12 .5V12h-.006a.998.998 0 11.292-1.952A.498.498 0 0115.5 10a.5.5 0 000-1 .5.5 0 01-.5-.5V6.1L5.09 7.9l.72 3.634a1.5 1.5 0 01.551 2.78l1.57 9.645L15 22.732V21.5zm.5 3.5H19v.08L8.412 26.92l-.32-1.974 6.908-1.2v.753a.5.5 0 00.5.5zM9.996 12.208a.998.998 0 11-1.996 0 .998.998 0 011.996 0zm2 .387a.998.998 0 100-1.996.998.998 0 000 1.996zm6.497-2.098a.998.998 0 100-1.996.998.998 0 000 1.996zm4.004-.998a.998.998 0 11-1.996 0 .998.998 0 011.996 0zm2 .998a.998.998 0 100-1.996.998.998 0 000 1.996z"
            fill="#535163"
        />
    </svg>
);

TicketsSvg.displayName = 'TicketsSvg';
export default TicketsSvg;
