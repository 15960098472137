import { GenericLazyString } from '@eventbrite/i18n';
import React from 'react';
import './CategoryBrowseBucket.scss';

interface CategoryBrowseBucketHeaderProps {
    viewMore?: React.ReactElement;
}

export const CategoryBrowseBucketHeader: React.FunctionComponent<
    CategoryBrowseBucketHeaderProps
> = (props) => {
    return (
        <div className="category-browse-bucket__header eds-text-hs">
            {props.children}
            {props.viewMore ? (
                <div className="category-browse-bucket__header--viewmore eds-text-bm">
                    {props.viewMore}
                </div>
            ) : null}
        </div>
    );
};

interface CategoryBrowseBucket {
    title?: string | GenericLazyString | React.ReactElement;
    viewMore?: React.ReactElement;
    wrappingClasses?: string;
}

/**
 * Simple component that will provide consistent spacing
 * between buckets and styling for header titles.
 */
export const CategoryBrowseBucket: React.FunctionComponent<
    CategoryBrowseBucket
> = (props) => {
    const { wrappingClasses } = props;
    const classes = wrappingClasses ? wrappingClasses : '';

    return (
        <section className={`category-browse-bucket ${classes}`}>
            {props.title ? (
                <CategoryBrowseBucketHeader viewMore={props.viewMore}>
                    {props.title}
                </CategoryBrowseBucketHeader>
            ) : null}
            {props.children}
        </section>
    );
};
