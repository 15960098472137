import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translationPropType } from '@eventbrite/i18n';
import { Icon } from '@eventbrite/eds-icon';
import { ICON_TYPE_PROP_TYPE } from '@eventbrite/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { STYLE_NONE } from '@eventbrite/eds-button';
import {
    ACTIVE_TEXT_BUTTON_COLOR,
    DISABLED_CLOSE_BUTTON_COLOR,
    ENABLED_CLOSE_BUTTON_COLOR,
    STYLES,
    STYLE_DEFAULT,
    STYLE_OUTLINED,
    STYLE_PALE,
} from './constants';

import './tag.scss';

import { CrossChunky } from '@eventbrite/eds-iconography';

const AsideElement = ({ image, label, iconType, iconColor }) => {
    let component = null;

    if (iconType) {
        component = (
            <div className="eds-tag__icon eds-tag__item" data-spec="tag-icon">
                <Icon type={iconType} size="small" color={iconColor} />
            </div>
        );
    }

    if (image) {
        component = (
            <div className="eds-tag__image eds-tag__item" data-spec="tag-image">
                <img className="eds-tag__image--img" src={image} alt={label} />
            </div>
        );
    }

    return component;
};

const CloseButton = ({ shouldShow, onClick, isDisabled, backgroundColor }) => {
    let component = null;

    if (shouldShow) {
        let buttonColor;

        if (isDisabled) {
            buttonColor = DISABLED_CLOSE_BUTTON_COLOR;
        } else if (backgroundColor) {
            buttonColor = ACTIVE_TEXT_BUTTON_COLOR;
        } else {
            buttonColor = ENABLED_CLOSE_BUTTON_COLOR;
        }

        component = (
            <div className="eds-tag__close-button eds-tag__item">
                <Button
                    aria-label="Close tag button"
                    onClick={onClick}
                    style={STYLE_NONE}
                    disabled={isDisabled}
                >
                    <Icon
                        type={<CrossChunky />}
                        size="small"
                        color={buttonColor}
                    />
                </Button>
            </div>
        );
    }

    return component;
};

export default class Tag extends PureComponent {
    static propTypes = {
        /**
         * The main content that the tag should be displaying
         **/
        children: PropTypes.node.isRequired,
        /**
         * Optional image URL that should be displayed by the tag
         **/
        image: PropTypes.string,
        /**
         * Label to be associated with the image if passed through
         **/
        label: translationPropType,
        /**
         * Optional icon that should be displayed by the tag
         **/
        iconType: ICON_TYPE_PROP_TYPE,
        //may not need, check with design
        /**
         * Prop dictating whether the tag should appear in an active state, or not.
         * Interaction still design still in progress so focus more on styling for now.
         **/
        initialIsActive: PropTypes.bool,
        // TODO: This should be updated to take an enum of colors so we can validate
        /**
         * Changes the color of the background color of the tag.
         **/
        backgroundColor: PropTypes.string,
        /**
         * Whether or not the tag should be set to a disabled state
         **/
        isDisabled: PropTypes.bool,
        /**
         * Whether or not the tag should include a close button
         * to dismiss it.
         **/
        isClosable: PropTypes.bool,
        /**
         * Whether or not the tag should change after being clicked and be reachable by keyboard.
         **/
        isStatic: PropTypes.bool,
        /**
         * Whether or not the tag close button should hide when the tag is not active.
         **/
        isCloseButtonRemovable: PropTypes.bool,
        /**
         * Callback function to be called when the tag is 'dismissed'
         **/
        onClose: PropTypes.func,
        /**
         * Callback function called when the specific tag is clicked as 'active'
         **/
        onActiveToggle: PropTypes.func,
        /**
         * Callback that is called on click but doesn't cause any internal state
         * change of "active" for the toggle if defined.
         */
        onClickStatic: PropTypes.func,
        /**
         * Whether or not to allow overflow of children to be visible
         */
        showOverflow: PropTypes.bool,
        /**
         * Changes the style of the tag
         * Available styles: default and outlined
         */
        style: PropTypes.oneOf(STYLES),
    };

    static defaultProps = {
        isClosable: false,
        isCloseButtonRemovable: false,
        isDisabled: false,
        isStatic: false,
        showOverflow: false,
        style: STYLE_DEFAULT,
    };

    constructor(props) {
        super(props);

        this.state = {
            isActive: props.initialIsActive,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.initialIsActive !== undefined) {
            this.setState({
                isActive: newProps.initialIsActive,
            });
        }
    }

    _toggleActive() {
        const { onActiveToggle, onClickStatic } = this.props;

        if (this._isInteractive()) {
            if (onClickStatic) {
                return onClickStatic();
            }

            this.setState(({ isActive }) => ({ isActive: !isActive }));

            const { isActive } = this.state;

            if (onActiveToggle) {
                onActiveToggle(!isActive);
            }
        }
    }

    _handleClose() {
        const { onClose, isDisabled } = this.props;

        if (!isDisabled && onClose) {
            onClose();
        }
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this._toggleActive();
        }
    }

    _isInteractive() {
        const { isDisabled, isStatic } = this.props;

        return !isDisabled && !isStatic;
    }

    _getTagClasses({
        style,
        isActive,
        isDisabled,
        isClosable,
        isInteractive,
        image,
        iconType,
        backgroundColor,
        showOverflow,
        __containerClassName,
    }) {
        const tagClasses = classNames('eds-tag', {
            [`eds-tag--${style}`]: style,
            [`eds-tag--active eds-tag--active--${style}`]: isActive,
            [`eds-tag--disabled eds-tag--disabled--${style}`]: isDisabled,
            [`eds-tag--closeable eds-tag--closeable--${style}`]: isClosable,
            [`eds-tag--interactive eds-tag--interactive--${style}`]:
                isInteractive,
            'eds-tag--with-image': image,
            'eds-tag--with-icon': iconType && !image,
            [`${backgroundColor} eds-tag__background--${style}`]:
                backgroundColor && !isDisabled,
            'eds-tag--show-overflow': showOverflow,
            [__containerClassName]: !!__containerClassName,
        });

        return tagClasses;
    }

    _getTextClasses({
        isDefault,
        isOutlined,
        isActive,
        shouldShowClose,
        backgroundColor,
        isDisabled,
    }) {
        const textClasses = classNames('eds-tag__text', 'eds-tag__item', {
            'eds-text-bs eds-text-weight--heavy': isDefault,
            'eds-text-bm': isOutlined,
            'eds-text-color--grey-800': isOutlined && !isActive,
            'eds-tag__text--active--outlined': isOutlined && isActive,
            'eds-tag__text--small-padding': shouldShowClose,
            'eds-tag__text--orange-shadow':
                isDefault && backgroundColor && !isDisabled,
            'eds-tag__text--background eds-text-color--white': backgroundColor,
        });

        return textClasses;
    }

    render() {
        const {
            image,
            children,
            isClosable,
            isDisabled,
            isCloseButtonRemovable,
            label,
            iconType,
            backgroundColor,
            showOverflow,
            style,
            __containerClassName,
        } = this.props;

        const { isActive } = this.state;
        const isInteractive = this._isInteractive();

        const shouldShowClose =
            (isClosable && !isCloseButtonRemovable) ||
            (isClosable && isActive && isCloseButtonRemovable);
        const isDefault = style === STYLE_DEFAULT;
        const isOutlined = style === STYLE_OUTLINED;

        const tagClasses = this._getTagClasses({
            style,
            isActive,
            isDisabled,
            isClosable,
            isInteractive,
            image,
            iconType,
            backgroundColor,
            showOverflow,
            __containerClassName,
        });

        const textClasses = this._getTextClasses({
            isDefault,
            isOutlined,
            isActive,
            shouldShowClose,
            backgroundColor,
            isDisabled,
        });

        let iconColor;
        let backgroundMask;
        let backgroundClasses;

        if (backgroundColor) {
            backgroundClasses = classNames({
                'eds-tag__background-mask': isDefault,
            });
            iconColor = ACTIVE_TEXT_BUTTON_COLOR;
            backgroundMask = <div className={backgroundClasses} />;
        }

        let interactiveProperties = {};

        if (isInteractive) {
            interactiveProperties = {
                tabIndex: 0,
                role: 'button',
                onClick: this._toggleActive.bind(this),
                onKeyDown: this._handleKeyDown.bind(this),
            };
        }

        return (
            <div
                {...interactiveProperties}
                className={tagClasses}
                key={children}
            >
                {backgroundMask}
                <AsideElement
                    image={image}
                    label={label}
                    iconType={iconType}
                    iconColor={iconColor}
                />
                <span className={textClasses}>{children}</span>
                <CloseButton
                    shouldShow={shouldShowClose}
                    onClick={this._handleClose.bind(this)}
                    isDisabled={isDisabled}
                    isActive={isActive}
                    backgroundColor={backgroundColor}
                />
            </div>
        );
    }
}
