import { History, Location } from 'history';
import React from 'react';
import { DateRange, DateType } from '../constants/dates';
import { FeatureFlags } from '../types';

export const useRouteContext = () => {
    const value = React.useContext(RouteContext);

    if (value === undefined) {
        throw new Error('useRouteContext must be used within RouteProvider');
    }

    return value;
};

export interface RouteContextShape {
    location: Location<string>;
    match: any;
    history?: History;
    parsedValues?: {
        activeTag?: string;
        date?: string;
        customDate?: DateRange;
        category?: string;
        bucket?: string;
    };
    featureFlags?: FeatureFlags;
}

export const RouteContext = React.createContext<Partial<RouteContextShape>>({});

/**
 * Due to our current version of react-router being quite old,
 * the exported hooks are unavailable for use. This is a thin wrapper
 * that passes route information into a hook friendly context.
 *
 * @param props
 * @returns
 */

export const RouteProvider: React.FunctionComponent<{
    location: any;
    match: any;
    history?: History;
    parsedValues?: {
        activeTag: string;
        date?: DateType;
        customDate?: DateRange;
        category?: string;
        bucket?: string;
    };
    featureFlags?: FeatureFlags;
}> = (props) => {
    const [value, setValue] = React.useState({
        location: props.location,
        match: props.match,
        parsedValues: props.parsedValues,
        history: props.history,
        featureFlags: props.featureFlags,
    });

    React.useEffect(() => {
        setValue({
            location: props.location,
            match: props.match,
            parsedValues: props.parsedValues,
            history: props.history,
            featureFlags: props.featureFlags,
        });
    }, [
        props.location,
        props.match,
        props.parsedValues,
        props.history,
        props.featureFlags,
    ]);

    return (
        <RouteContext.Provider value={value}>
            {props.children}
        </RouteContext.Provider>
    );
};

export default RouteProvider;
