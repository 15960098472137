import * as React from 'react';

const GhostGraphicSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 144 144" {...props}>
        <g fill="none" fillRule="evenodd">
            <g>
                <path d="M0 0h144v144H0z" />
                <path
                    d="M108 110.352v-57c0-19.882-16.118-36-36-36-19.883 0-36 16.118-36 36v57l9-9 9 9 9-9 9 9 9-9 9 9 9-9 9 9z"
                    fill="#F9F9FA"
                />
                <path
                    d="M81 18.486a36.107 36.107 0 00-9-1.134c-19.883 0-36 16.118-36 36v57l9-9 9 9v-57c0-16.774 11.472-30.87 27-34.866"
                    fill="#D2D6DF"
                />
                <path
                    d="M81 99.231l9 9 9-9 7.5 7.5V53.352c0-19.023-15.475-34.5-34.5-34.5-19.023 0-34.5 15.477-34.5 34.5v53.38l7.5-7.5 9 9 9-9 9 9 9-9zm28.5 14.742l-10.5-10.5-9 9-9-9-9 9-9-9-9 9-9-9-10.5 10.5v-60.62c0-20.678 16.822-37.5 37.5-37.5 20.677 0 37.5 16.822 37.5 37.5v60.62z"
                    fill="#363A43"
                />
                <path
                    fill="#363A43"
                    d="M61.5 84.852h21v-3h-21zM56.813 57.852a2.812 2.812 0 11-5.626 0 2.812 2.812 0 115.626 0M92.438 57.852a2.812 2.812 0 11-5.626 0 2.812 2.812 0 115.626 0M61.376 115.624h3v-3h-3zM61.376 121.624h3v-3h-3zM79.313 115.624h3v-3h-3zM79.313 121.624h3v-3h-3zM43.75 117h3v-3h-3zM43.75 123h3v-3h-3zM43.75 129h3v-3h-3zM97.25 115.5h3v-3h-3zM97.25 121.5h3v-3h-3zM97.25 127.5h3v-3h-3z"
                />
            </g>
        </g>
    </svg>
);

GhostGraphicSvg.displayName = 'GhostGraphicSvg';
export default GhostGraphicSvg;
