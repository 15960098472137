import React from 'react';
import { useIsInViewPort, useSetDisabledNav } from '../hooks';
import { NavDirections, SlideConfig } from '../types';

const SLIDE_CLASS_MAP: { [slideCount: string]: string } = {
    '1-tablet': 'simple-carousel__slide__tablet--one',
    '2-tablet': 'simple-carousel__slide__tablet--two',
    '3-tablet': 'simple-carousel__slide__tablet--three',
    '4-tablet': 'simple-carousel__slide__tablet--four',
    '5-tablet': 'simple-carousel__slide__tablet--five',
    '6-tablet': 'simple-carousel__slide__tablet--six',
    '1-desktop': 'simple-carousel__slide__desktop--one',
    '2-desktop': 'simple-carousel__slide__desktop--two',
    '3-desktop': 'simple-carousel__slide__desktop--three',
    '4-desktop': 'simple-carousel__slide__desktop--four',
    '5-desktop': 'simple-carousel__slide__desktop--five',
    '6-desktop': 'simple-carousel__slide__desktop--six',
};

export const Slide: React.FunctionComponent<SlideProps> = (props) => {
    const {
        slidesVisible,
        lazilyRenderOffScreenElements,
        slideNumber,
        setDisabledNav,
        childrenLength,
        fullyEnableNavigation = false,
        isFirstPage = false,
        isLastPage = false,
    } = props;

    const slideRef = React.useRef<HTMLDivElement>(null);
    const isLastSlide = slideNumber === childrenLength - 1;
    const isFirstSlide = slideNumber === 0;

    const { hasEnteredViewport, isVisible } = useIsInViewPort({
        ref: slideRef,
        trackVisibility: isLastSlide || isFirstSlide,
    });
    useSetDisabledNav({
        isLastSlide,
        isFirstSlide,
        isVisible,
        setDisabledNav,
        fullyEnableNavigation,
        isFirstPage,
        isLastPage,
    });

    const slideClassSelector = slidesVisible;
    const desktopSelector = `${slideClassSelector.desktop}-desktop`;
    const tabletSelector = `${slideClassSelector.tablet}-tablet`;

    const slideClasses = `simple-carousel__slide ${SLIDE_CLASS_MAP[desktopSelector]} ${SLIDE_CLASS_MAP[tabletSelector]}`;

    /**
     * Prevent rendering this slide if
     * should lazily render, is offscreen,
     * and should not initiall be visible
     */
    const shouldNotRenderChild =
        lazilyRenderOffScreenElements &&
        slideNumber + 1 > slidesVisible.desktop &&
        !hasEnteredViewport;

    return (
        <div className={slideClasses} ref={slideRef}>
            {shouldNotRenderChild ? null : props.children}
        </div>
    );
};

interface SlideProps {
    slidesVisible: SlideConfig;
    slideNumber: number;
    childrenLength: number;
    lazilyRenderOffScreenElements?: boolean;
    setDisabledNav: (direction?: NavDirections) => void;
    /***
     * Disable side navigation icons
     */
    fullyEnableNavigation?: boolean;
    isFirstPage?: boolean;
    isLastPage?: boolean;
}
