import { SimpleCarousel } from '@eventbrite/collection-carousel';
import { track } from '@eventbrite/datalayer-library';
import { FormattedEvent, ShareUTMOptions } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import { logEvent } from '@eventbrite/statsig';
import React from 'react';

import DiscoverVerticalEventCard from '../../components/DiscoverEventCard/DiscoverVerticalEventCard';
import { EventLoadingState } from '../../pages/categoryBrowse/components/CategoryBrowseEventsLoading';
import {
    HOMEPAGE_PAID_CAROUSEL_EVENT,
    HOMEPAGE_PAID_EVENTS_CAROUSEL_CTR_CLICK,
} from '../../pages/home/constants/analytics';
import { useCategoryEventsQuery } from '../../pages/home/hooks/events/events';
import './PaidEventsCarousel.scss';

interface PaidEventsCarouselProps {
    isAuthenticated: boolean;
    locale: string;
    page: string;
    isMobile: boolean;
    affCode: string;
    location: any;
}

const utmOptions: ShareUTMOptions = {
    'utm-campaign': 'social',
    'utm-content': 'attendeeshare',
    'utm-medium': 'discovery',
    'utm-term': '',
    'utm-share-source': 'category-browse',
};

export enum NavDirections {
    Left = 'left',
    Right = 'right',
}
const renderLoadingSkeleton = (isMobile: boolean) => {
    return (
        <div className="loader">
            <LoadingSkeleton height={isMobile ? '400px' : '426px'} />
        </div>
    );
};

const renderEventLoadingState = () => {
    return (
        <div className="eventLoader">
            <EventLoadingState numberOfEvents={5} />
        </div>
    );
};

export const PaidEventsCarousel = ({
    isAuthenticated,
    locale,
    page,
    isMobile,
    affCode,
    location,
}: PaidEventsCarouselProps) => {
    const [pageSize, setPageSize] = React.useState(1);
    const [pageIndex, setPageIndex] = React.useState(1);

    const PAGE_SIZE = 40;

    const { data, isLoading } = useCategoryEventsQuery({
        queryOverload: {
            price: 'paid',
            page: 1,
            page_size: PAGE_SIZE,
            aggs: {},
            dates: ['current_future'],
        },
        refetchOnMount: false,
        enabled: true,
        location: location,
    });

    React.useEffect(() => {
        if (location.currentPlace) {
            track({
                eventName: 'PaidEventsCarouselView',
                eventData: {
                    searchLocation: location.currentPlace,
                    page: page,
                },
            });
        }
    }, [location.currentPlace, page]);

    const moduleIndex = isMobile ? 40 : 10;

    if (isLoading && pageIndex === 1) {
        return renderLoadingSkeleton(isMobile);
    } else if (isLoading) {
        return renderEventLoadingState();
    }

    let events: FormattedEvent[] = data?.events || [];

    const maxPrice = 30;
    // maxPrice object for an event has maxPrice value as 1700 for a $17 ticket,
    // hence we need the division to get the actual value
    const MAX_PRICE_HUNDREDS_DIVISOR = 100;

    events = events.filter(
        (e) =>
            e.maxPrice &&
            e.maxPrice.maxPriceValue / MAX_PRICE_HUNDREDS_DIVISOR <= maxPrice,
    );

    // Show the carousel only if we have atleast 3 events
    if (events.length < 3) {
        return null;
    }

    // Since the events have a price filter, the page count may not be the same as
    // the one from original search response i.e. data?.pagination?.page_count
    const pageCount = Math.ceil(events.length / PAGE_SIZE);
    const numberFormatConfig = {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        currency: events[0]?.minPrice?.currency,
    };

    // Returns the minPrice as per the locale's currency e.g. $20 for USA, £20 for London etc.
    const formattedMaxPrice = new Intl.NumberFormat(
        locale.replace('_', '-'),
        numberFormatConfig,
    )
        .format(maxPrice)
        .split('.')[0];

    const handleNavigationClick = (direction: string) => {
        if (direction === 'left' && (pageSize - 1) % moduleIndex == 0) {
            setPageSize(1);
            setPageIndex(pageIndex - 1);
        } else if (direction === 'right' && pageSize % moduleIndex == 0) {
            setPageSize(1);
            setPageIndex(pageIndex + 1);
        } else {
            direction === 'left'
                ? setPageSize(pageSize - 1)
                : setPageSize(pageSize + 1);
        }
        logEvent(HOMEPAGE_PAID_EVENTS_CAROUSEL_CTR_CLICK);
    };

    return (
        <div
            className="paid-events-carousel"
            data-heap-id="paid-events-carousel"
            data-event-bucket-label={`paid-events-carousel-${page}`}
        >
            <section className="paid-events-carousel-container">
                <SimpleCarousel
                    headerTitle={
                        <div className="title-subtitle-container">
                            <h2>
                                {gettext(
                                    'Events %(formattedMaxPrice)s and under',
                                    {
                                        formattedMaxPrice,
                                    },
                                )}
                            </h2>
                        </div>
                    }
                    slidesVisible={{ desktop: 4, tablet: 3 }}
                    onNavigationClick={handleNavigationClick}
                    lazilyRenderOffScreenElements={true}
                    isFirstPage={pageIndex === 1}
                    isLastPage={pageIndex === pageCount}
                >
                    {events.map((event) => (
                        <div
                            className="paid-events-carousel-event-card"
                            data-heap-id="homepage-paid-events-carousel-click-event"
                            key={event.id}
                        >
                            <DiscoverVerticalEventCard
                                locale={locale || 'en_US'}
                                isLazyImage={true}
                                isAuthenticated={isAuthenticated || false}
                                event={event}
                                affCode={affCode}
                                statsigLocationString={
                                    HOMEPAGE_PAID_CAROUSEL_EVENT
                                }
                                data-testid={`homepage-event-card-${event.id}`}
                                shareOptions={{
                                    utmOptions,
                                    trackingCategory: 'eventcard',
                                }}
                            />
                        </div>
                    ))}
                </SimpleCarousel>
            </section>
        </div>
    );
};

export default PaidEventsCarousel;
