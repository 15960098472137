import times from 'lodash/times';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './loadingCards.scss';

const LoadingCard = () => (
    <div className="loading-card">
        <div className="loading-card__aside">
            <div className="loading-card__item loading-card__item-0" />
            <div className="loading-card__item loading-card__item-1" />
            <div className="loading-card__item loading-card__item-2" />
            <div className="loading-card__item loading-card__item-3" />
        </div>
        <div className="loading-card__item loading-card__main" />
    </div>
);

const _getDeclaredCards = (cardCount) =>
    times(cardCount, (index) => <LoadingCard key={`loading-card-${index}`} />);

/*
 * Rough implementation of a configurable loading state component for
 * event style cards on search experiences. Currently structured to support
 * a single column of cards of variable counts and styles.
 */
export default class LoadingCards extends PureComponent {
    static propTypes = {
        /*
         * Determines loading card style to display
         * list, grid
         */
        style: PropTypes.oneOf(['list', 'grid', 'list-square']),
        /*
         * The number of cards to be rendered
         */
        cardCount: PropTypes.number,
    };

    static defaultProps = {
        style: 'list',
        cardCount: 5,
    };

    render() {
        const { style, cardCount, dynamicWidth, dynamicHeight } = this.props;

        const cardClasses = classNames('loading-cards__container ', {
            'eds-l-pad-top-6 eds-l-pad-hor-6': !dynamicWidth,
            'loading-cards--dynamic': dynamicWidth,
            'loading-cards--dynamic--height': dynamicHeight && dynamicWidth,
            'loading-cards--list': style === 'list',
            'loading-cards--grid': style === 'grid',
            'loading-cards--list-square': style === 'list-square',
        });
        const cards = _getDeclaredCards(cardCount);

        return <section className={cardClasses}>{cards}</section>;
    }
}
